<template>
  <div>
    <div class="p-banner">
      <img alt="" class="p-banner-bg" src="../../assets/img/product/banner4.png">
      <div class="container">
        <div class="p-banner-title">{{ menuType == 1 ? '分子蒸馏技术' : menuType == 2 ? '分子蒸馏器' : '分子蒸馏成套设备' }}</div>
        <div class="p-banner-sub-title">{{ menuType == 1 ? '分子蒸馏技术' : menuType == 2 ? '分子蒸馏器' : '分子蒸馏成套设备' }}</div>
      </div>
    </div>
    <div class="page-menu d-flex-center">
      <div :class="menuType == 1 ? 'on' : ''" @click="menuType = 1">技术介绍</div>
      <div :class="menuType == 2 ? 'on' : ''" @click="menuType = 2">核心设备</div>
      <div :class="menuType == 3 ? 'on' : ''" @click="menuType = 3">成套装置</div>
    </div>
    <div v-if="menuType == 1" class="container">
      <div class="product-item-title">技术原理</div>
      <el-row class="flex-warp mb-40 radius-10 box-shadow" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="21" :md="21" :sm="21" :xl="21" :xs="24">
          <div class="dot-box">
            <div class="dot-list">分子蒸馏是一种特殊的液--液分离技术，它在高真空下操作，能使液体在远低于其沸点的温度下将其分离。它是利用分子运动平均自由程的原理，将液体混合物稍稍加热，使液体分子从液面逸出，轻分子落在冷凝面而重分子又返回，如此实现液体的分离。分子蒸馏过程通过如下四个步骤完成：</div>
            <div class="dot-list">① 分子从液相向蒸发表面扩散</div>
            <div class="dot-list">② 分子在液相表面上的自由蒸发</div>
            <div class="dot-list">③ 分子从蒸发表面向冷凝面飞射</div>
            <div class="dot-list">④ 分子在冷凝面上冷凝</div>
          </div>
        </el-col>
        <el-col :lg="3" :md="3" :sm="3" :xl="3" :xs="24">
          <div class="preview-image"><img class="box-img" v-image-preview style="height: 100%" src="../../assets/img/product/fzzl1-1.png"></div>
        </el-col>
      </el-row>
      <div class="product-item-title">典型应用</div>
      <el-row class="flex-warp mb-40 radius-10" justify="space-between" type="flex" v-animate-on-intersect>
        <el-col :lg="13" :md="13" :sm="13" :xl="13" :xs="24">
          <div class="preview-image"><img class="box-img" v-image-preview style="height: 100%" src="../../assets/img/product/fzzl1-2.png"></div>
        </el-col>
        <el-col :lg="11" :md="11" :sm="11" :xl="11" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><div class="dot"></div>脱除树脂和聚合物中的挥发性组份</div>
            <div class="dot-list"><div class="dot"></div>脱除天然蜡中的低沸点组分</div>
            <div class="dot-list"><div class="dot"></div>浓缩和提纯乳酸、甘油、芳香胺、脂肪酸酰胺聚苯化合物</div>
            <div class="dot-list"><div class="dot"></div>分离提纯二聚脂肪酸、杀虫剂、除菌剂、除草剂、高碳醇、石蜡、医药中间体</div>
            <div class="dot-list"><div class="dot"></div>回收环丁砜、二甲基亚砜、二甲基甲酰胺和二甲基乙酰胺</div>
            <div class="dot-list"><div class="dot"></div>化学品除臭和去除农药残留</div>
            <div class="dot-list"><div class="dot"></div>精制丙烯酸及丙烯酸酯、碳酸酯</div>
            <div class="dot-list"><div class="dot"></div>纯化玫瑰油、山仓子油、桉叶油、香茅油、橙油、紫罗兰酮等芳香物质</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">技术特点</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>操作真空度高：</span>由于分子蒸馏的冷热面间的间距小于轻分子的平均自由程，蒸发面的实际操作真空度比传统真空蒸馏的真空度高，操作压可达到0.1～1Pa数量级</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>操作温度低：</span>分子蒸馏依靠分子运动平均自由程的差别实现分离，并不需要到达物料的常压沸点</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>离过程中物料受热时间短：</span>分子蒸馏在蒸发过程中，物料被强制形成很薄的液膜，并被定向推动，使得液体在分离器中停留时间很短，特别是轻分子，一经逸出就马上冷凝，受热时间更短，物料的热损伤极小</div></div>
      </div>
    </div>
    <div v-else-if="menuType == 2" class="container">
      <div class="product-item-title">概述</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>将产品从高粘度的反应聚合副产物中分离或将高沸点、高粘度、热敏物料蒸馏提纯，常规的蒸馏设备难以得到理想的结果，特殊设计的分子蒸馏器是解决此类问题的利器。 分子蒸馏器将加热和冷凝组合在一起，并通过成膜刮板将进入加热面的物料连续成膜分散，让蒸发组份快速从加热面逸出，在冷凝面被冷凝收集。分子蒸馏的这一特点让其可以有效处理粘性材料和适用于有污垢的应用。</div>
      <div class="product-item-title">结构</div>
      <div class="page-text">分子蒸馏器由带加热夹套的外筒体、内冷器、刮板刮膜器、布液和密封结构、驱动电机等组成。高精密的传动与机械密封设计、整体加工成型的蒸发筒体、独特的液体分布器和防溅设备是保证设备长期平稳运行的关键。</div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">光洁的外筒体</div>
            <div class="preview-image"> <img class="page-img" src="../../assets/img/product/shjj2-1.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">防贱板</div>
            <div class="preview-image"> <img class="page-img" src="../../assets/img/product/shjj2-2.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">内冷器</div>
            <div class="preview-image"> <img class="page-img" src="../../assets/img/product/shjj2-3.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">组装成品</div>
            <div class="preview-image"> <img class="page-img" src="../../assets/img/product/shjj2-4.png" v-image-preview alt=""></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">设计特征</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">根据具体物料体系进行设备设计，真正做到工艺和设备的最佳匹配</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">对副产聚合物含量高、黏度大的体系，可以设置辅助出料机构辅助出料</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">独特的成膜结构设计，低转速即可实现良好成膜，极大的延长刮板刀片、机械密封和驱动轴承的使用寿命</div>
      </div>
    </div>
    <div v-else-if="menuType == 3" class="container">
      <div class="product-item-title">概述</div>
      <div class="grey-box page-text mb-40"  v-animate-on-intersect>一台设计制造优良的分子蒸馏器，如果没有适配的辅助系统配合，也难以发挥出应有的性能，敏恒科技利用其专业技能和丰富经验，可为您提供从公用工程配套到真空系统集成的成套设备，让您无忧获取性能最佳的分子蒸馏系统。</div>
      <div class="product-item-title">成套设备组成</div>
      <div class="page-text">分子蒸馏成套设备由分子蒸馏器、轻重相接收罐、冷阱、真空机组等设备和管道阀门、电气仪表、梯子平台、保温伴热等二级子单元组成，根据规模大小分可为撬装设备或现场安装系统。撬装设备将在生产车间完成组装和测试，运输到客户现场，接上公用工程和进出物料管线即可投入运行；现场安装系统将在客户现场完成组装和调试。</div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">分子蒸馏成套撬装设备</div>
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/fzzl2-1.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">分子蒸馏现场安装系统</div>
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/fzzl2-2.png" v-image-preview alt=""></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">成套设备的优点</div>
      <div class="img-list mb-40"  v-animate-on-intersect>
        <div class="page-text f-bold">分子蒸馏成套设备的设计和制造涉及很多方面，包括工艺需要的预热、预脱气、单级蒸发或是多级蒸发以及真空、公用工程、产品和废料处理等。敏恒的量身定制将确保整个系统实现最佳。其中的细节包括： </div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">高真空条件下的稳定上料和流量控制</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">根据物料特性、产能和分离要求的蒸发级数确定、各级间蒸发速率分配</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">根据物料特性、产能和分离要求的蒸发级数确定、各级间蒸发速率分配</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">采用适当的预处理工艺，降低对分子蒸馏加热和冷却的面积要求，节省系统投资</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">根据物料特性，匹配正确的真空系统和尾气处理设备，实现长周期稳定运行</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // name: "分子蒸馏",
  data(){
    return{
      menuType:1
    }
  }
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
